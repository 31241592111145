import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Stack,
  Chip,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { filter, find, uniq } from "lodash";
import { getDateRange } from "./helpers";
import { StringHelper } from "@bryntum/gantt";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateTask = ({
  record,
  open,
  crews,
  resources,
  isDailyReportView,
  reportDate,
  onClose,
  updateTasksFromCreate,
}) => {
  const taskName = record.name;
  const [dateRange, setDateRange] = useState({
    startDate: isDailyReportView ? moment(reportDate) : moment(),
    endDate: isDailyReportView ? moment(reportDate) : moment(),
  });
  const [crew, setCrew] = useState("");
  const [selectedRes, setSelectedRes] = React.useState([]);
  const [error, setError] = useState("");

  const handleDelete = (s) => {
    setSelectedRes((res) => selectedRes.filter((r) => r !== s));
  };

  const validate = () => {
    let err = "";
    const start = dateRange.startDate.format("MM-DD-YYYY");
    const end = dateRange.endDate.format("MM-DD-YYYY");
    if (moment(end).isBefore(moment(start))) {
      err = "Start date cannot be later than End date";
      return err;
    }
    if (!crew) {
      err = "Please select Crew";
      return err;
    }
    if (selectedRes.length === 0) {
      err = "Please select Resources";
      return err;
    }
  };

  const handleSave = () => {
    const validation = validate();
    setError(validation);
    if (!validation) {
      // save
      const dateRangeArr = getDateRange(dateRange.startDate, dateRange.endDate);
      const { originalData } = record;
      const dateList = dateRangeArr.map((d) => {
        return {
          id: `unsaved-${StringHelper.generateUUID()}`,
          key: `uikey-${StringHelper.generateUUID()}`,
          parentId: originalData.parentId || originalData.id,
          eventId: originalData.id,
          resourceId: "",
          startDate: d,
          duration: 8,
          durationUnit: "h",
          units: 100,
          tags: [],
          toSync: true,
          isNew: true,
        };
      });
      const saveList = selectedRes.map((s) => {
        const tmpList = dateList.map((dl) => ({ ...dl, resourceId: s }));
        return tmpList;
      });
      updateTasksFromCreate(saveList.flat());
    }
  };

  let resourcesOptions = [];
  if (crew) {
    resourcesOptions = filter(resources, (r) => {
      if (crew === "unassigned") {
        return r.Crew__c === null;
      }
      return r.Crew__c === crew;
    });
  }

  const handleChange = (e) => {
    if(e.target.value[e.target.value.length - 1] === "all") {
      const temp = [...selectedRes, ...resourcesOptions.map(r => r.id)];
      if(uniq(temp).length=== selectedRes.length)
      {
        setSelectedRes([]); 
      }
      else{
        setSelectedRes(() => uniq(temp));
      }
    } else {
      setSelectedRes(() => [...e.target.value]);
      
    }
  };

  const getTaskName = () => {
    const taskName = [record.name];
    if (record.parent) {
      let rec = record.parent;
      while (rec) {
        if (rec.originalData.name) {
          taskName.unshift(rec.originalData.name);
        }
        rec = rec.parent;
      }
    }
    if (taskName.length === 1) return taskName[0];
    return taskName.join(" > ");
  };

  const crewWithResources = [];
  crews.forEach((c) => {
    if (c.value) {
      const res = filter(resources, { Crew__c: c.value });
      if (res.length > 0) {
        crewWithResources.push(c);
      }
    }
  });

  const unassignedRes = filter(resources, { Crew__c: null });

  const allSelected = () => {
    const filteredAll = resourcesOptions.filter((ro) => selectedRes.includes(ro.id));
      return filteredAll.length === resourcesOptions.length; 
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Create Task</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {getTaskName()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Start Date"
                    value={dateRange.startDate}
                    onChange={(e) =>
                      setDateRange((prev) => ({ ...prev, startDate: e }))
                    }
                    fullWidth
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="End Date"
                    value={dateRange.endDate}
                    onChange={(e) =>
                      setDateRange((prev) => ({ ...prev, endDate: e }))
                    }
                    fullWidth
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="crew-select">Crew</InputLabel>
              <Select
                labelId="crew-select"
                id="crew-select"
                value={crew}
                label="Age"
                onChange={(e) => setCrew(e.target.value)}
              >
                {crewWithResources.map((c) => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
                {unassignedRes.length > 0 && (
                  <MenuItem value="unassigned">Unassigned</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {crew && (
            <Grid item xs={6}>
              <FormControl size="small" fullWidth>
                <InputLabel id="crew-resources">Crew Resources</InputLabel>
                <Select
                  labelId="crew-resources"
                  id="crew-resources-checkbox"
                  multiple
                  value={selectedRes}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => `${selected.length} Selected`}
                  MenuProps={MenuProps}
                >
                  {resourcesOptions.length > 0 && (
                    <MenuItem value="all">
                      <Checkbox checked={allSelected()} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  )}
                  {resourcesOptions.map((o) => (
                    <MenuItem key={o.id} value={o.id}>
                      <Checkbox checked={selectedRes.indexOf(o.id) > -1} />
                      <ListItemText primary={o.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {selectedRes.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Selected Resources:
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                {selectedRes.map((s) => (
                  <Chip
                    key={s}
                    label={find(resources, { id: s })?.name}
                    variant="outlined"
                    onDelete={() => handleDelete(s)}
                  />
                ))}
              </Stack>
            </Grid>
          )}
          {error && (
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ color: "red" }} gutterBottom>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTask;
