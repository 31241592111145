import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, find, uniq } from "lodash";
import { StringHelper } from "@bryntum/gantt";
import moment from "moment"; 

const initialState = {
  resources: [],
  assignments: [],
  holidays: [],
  crews: [],
  variance: [],
  completedDates: []
};

export const lookaheadDataSlice = createSlice({
  name: "lookaheadData",
  initialState,
  reducers: {
    setResourcesData: (state, action) => {
      state.resources = action.payload;
    },
    setVarianceData: (state, action) => {
      state.variance = action.payload;
    },
    setCompletedDates: (state, action) => {
      state.completedDates = [...action.payload];
    },
    setAssignmentsData: (state, action) => {
      // state.assignments = [];
      let tmp = [];
      const { payload } = action;
      switch (payload.type) {
        case "LOAD":
          return { ...state, assignments: payload.data };
        case "HOLIDAYS":
          return { ...state, holidays: payload.data };
        case "CREWS":
          return { ...state, crews: payload.data };
        case "UPDATE":
          return {
            ...state,
            assignments: [...state.assignments, payload.data],
          };
        case "UPDATE_DURATION":
          tmp = state.assignments.map((s) => {
            if (s.id === payload.data.assignId) {
              return {
                ...s,
                duration: payload.data.duration,
                toSync: true,
              };
            } else {
              return s;
            }
          });
          return { ...state, assignments: tmp };
        case "UPDATE_NOTES":
          tmp = state.assignments.map((s) => {
            if (s.id === payload.data.assignId) {
              return {
                ...s,
                notes: payload.data.notes,
                toSync: true,
              };
            } else {
              return s;
            }
          });
          return { ...state, assignments: tmp };
        case "REPLACE":
          tmp = state.assignments.map((s) => {
            if (s.id === payload.data.id) {
              let isNew = false;
              if (s.id.includes("unsaved")) {
                isNew = true;
              }
              return { ...payload.data, toSync: true, isNew: isNew };
            } else {
              return s;
            }
          });
          return { ...state, assignments: tmp };
        case "REFRESH_SAVED":
          const savedData = payload.data.savedRes;
          tmp = state.assignments.map((s) => {
            const findEl = find(
              savedData,
              (f) =>
                f.key === s.key &&
                f.Contact__c === s.resourceId &&
                f.WBS__c === s.eventId &&
                f.Task_Date__c === moment(s.startDate).format("YYYY-MM-DD")
            );
            if (findEl) {
              return {
                id: findEl.Id,
                key: findEl.key, // this is a unique key for ui
                parentId: findEl.Parent_WBS__c,
                eventId: findEl.WBS__c,
                resourceId: findEl.Contact__c,
                startDate: findEl.Task_Date__c,
                duration: findEl.Scheduled_Hours__c,
                status: findEl.Status__c || null,
                isDeleted: findEl.isDeleted__c,
                rDeleted: findEl.Resource_Deleted__c,
                durationUnit: "h",
                units: 100,
                tags: findEl.Tags__c ? findEl.Tags__c.split(";") : [],
                notes: findEl.Notes__c || "",
              };
            } else {
              return s;
            }
          });
          return { ...state, assignments: tmp };
        case "UPDATE_SUBTASK":
          tmp = state.assignments.map((s) => {
            if (s.id === payload.data.assignId) {
              return {
                ...s,
                eventId: payload.data.id,
                name: payload.data.label,
                toSync: true,
              };
            } else {
              return s;
            }
          });
          return { ...state, assignments: tmp };
        case "UPDATE_TAG":
          tmp = state.assignments.map((s) => {
            if (s.id === payload.data.assignId) {
              const tags = uniq([...s.tags, payload.data.tag]);
              return { ...s, tags, toSync: true };
            } else {
              return s;
            }
          });
          return { ...state, assignments: tmp };
        case "DELETE_TAG":
          tmp = state.assignments.map((s) => {
            if (s.id === payload.data.assignId) {
              const tags = [...s.tags];
              tags.splice(payload.data.index, 1);
              return { ...s, tags, toSync: true };
            } else {
              return s;
            }
          });
          return { ...state, assignments: tmp };
        case "DELETE_ASSIGNMENT":
          if (payload.data.assignId.includes("unsaved")) {
            // remove item as it's new and not syced
            tmp = state.assignments.filter(
              (a) => a.id !== payload.data.assignId
            );
          } else {
            tmp = state.assignments.map((s) => {
              if (s.id === payload.data.assignId) {
                return {
                  ...s,
                  deleted: true,
                  Reason_for_Variance__c: payload.data.Reason_for_Variance__c,
                  Reason_for_Variance_Notes__c:
                    payload.data.Reason_for_Variance_Notes__c,
                };
              } else {
                return s;
              }
            });
          }

          return { ...state, assignments: tmp };
        case "DELETE_RESOURCE": {
          //console.log('pre state assignments:: ',JSON.stringify(state.assignments));
          //console.log('pre state :: t_res',JSON.stringify(state.resources));
          let t_res = state.resources;
          if (payload.data.resourceId) {
            let onlyUnsaved = true;
            let taskAvailable = false;
            for (let _s of state.assignments) {
              let s = cloneDeep(_s);
              let hold = false;
              if (s.resourceId === payload.data.resourceId) {
                taskAvailable = true;
                s.rDeleted = true;
                s.toSync = true;
                if (!s.id.includes("unsaved")) {
                  onlyUnsaved = false;
                }
                if (s.id.includes("unsaved")) {
                  hold = true;
                }
              }
              if (hold === false) {
                tmp.push(s);
              }
            }
            if (!taskAvailable || onlyUnsaved) {
              const nt = {
                id: `unsaved-${StringHelper.generateUUID()}`,
                key: `uikey-${StringHelper.generateUUID()}`,
                parentId: null,
                eventId: null,
                resourceId: payload.data.resourceId,
                startDate: payload.data.dailyReportDate,
                duration: 0,
                status: "Not Completed",
                rDeleted: true,
                durationUnit: "h",
                units: 0,
                tags: [],
                toSync: true,
              };
              tmp.push(nt);
            }
            t_res = state.resources.filter(
              (r) => r.id !== payload.data.resourceId
            );
            //console.log('post state assignments:: ',JSON.stringify(tmp));
            //console.log('post state :: t_res',JSON.stringify(t_res));
          }
          return { ...state, assignments: tmp, resources: t_res };
        }
        default:
          return state;
      }
    },
    resetState: (state) => {
      state.assignments = [];
      state.resources = [];
    }
  },
});

export const { setResourcesData, setAssignmentsData, setVarianceData, setCompletedDates, resetState } =
  lookaheadDataSlice.actions;

export default lookaheadDataSlice.reducer;
