// helpers for lookahead page
import { filter, find, uniq, uniqBy } from "lodash";
import { StringHelper, DateHelper } from "@bryntum/gantt";

import { arrayToTree } from "helpers/task";
import moment from "moment";

export const processResources = (
  data,// created res
  timecardMap,
  tasks,
  isDailyReportView,
  addAssignments
) => {
  const resources = [];
  const contactIdDetails = {};
  const recIds = [];
  if (data) {
    Object.keys(data).forEach((r) => {
      data[r].forEach((res) => {
        recIds.push(res.Contact__c);
        if (res.Crew__r?.Name && res.Contact__c) {
          if (!contactIdDetails[res.Contact__c]) {
            contactIdDetails[res.Contact__c] = {
              Id: res.Contact__c,
              Name: res.Contact__r.Name,
              Primary_Role__c: res.Contact__r.Primary_Role__c,
              Crew__r: res.Crew__r?.Name,
              Crew__c: res.Crew__c,
              Crew_Accountable: res.Crew__r?.accountableName
            };
          }
        }
        if (addAssignments === true) {
          const tp = {
            Contact__c: res.Contact__c,
            Crew__c: res.Crew__c,
            Crew__r: res.Crew__r ? res.Crew__r.Name : "Unassigned",
            Crew_Accountable: res.Crew__r?.accountableName || "",
            id: res.Contact__c,
            name: res.Contact__r.Name,
            role: res.Contact__r.Primary_Role__c,
            calendar: "allday",
          };
          if (timecardMap && timecardMap[tp.Contact__c]) {
            tp.timeCardAvailable = true;
            tp.timeCardDetail = timecardMap[tp.Contact__c];
            tp.timeOffDates = timecardMap[tp.Contact__c].timeOffDates;
          }
          resources.push(tp);
        }
      });
    });
  }
  //console.log('contactIdDetails :: ',contactIdDetails);
  let resourceDeleted = [];
  if (tasks && tasks?.length > 0) {
    tasks.forEach((tsk) => {
      if (tsk.Resource_Deleted__c) {
        if (timecardMap && !timecardMap[tsk.Contact__c]) {
          resourceDeleted.push(tsk.Contact__c);
        }
      }
      if (!tsk.isDeleted__c && !tsk.Resource_Deleted__c) {
        let res = {
          Id: tsk.Contact__c,
          Name: tsk.Contact__r.Name,
          Primary_Role__c: tsk.Contact__r.Primary_Role__c,
        };
        let emFound = resources?.find((rs) => rs.id === res.Id);
        if (!emFound) {
          const tp = {
            Contact__c: res.Id,
            Crew__r: contactIdDetails[res.Id]?.Crew__r
              ? contactIdDetails[res.Id]?.Crew__r
              : "Unassigned",
            Crew__c: contactIdDetails[res.Id]?.Crew__c || "",
            id: res.Id,
            name: res.Name,
            role: res.Primary_Role__c,
            calendar: "allday",
            Crew_Accountable: contactIdDetails[res.Id]?.Crew_Accountable || "",
          };
          if (timecardMap && timecardMap[tp.Contact__c]) {
            tp.timeCardAvailable = true;
            tp.timeCardDetail = timecardMap[tp.Contact__c];
            tp.timeOffDates = timecardMap[tp.Contact__c].timeOffDates;
          }
          resources.push(tp);
        }
      }
    });
  }
  if (isDailyReportView && timecardMap) {
    Object.keys(timecardMap).forEach((conId) => {
      let emFound = resources?.find((rs) => rs.id == conId);
      if (!emFound) {
        const tp = {
          Contact__c: conId,
          Crew__r: contactIdDetails[conId]?.Crew__r
            ? contactIdDetails[conId]?.Crew__r
            : "Unassigned",
          Crew__c: contactIdDetails[conId]?.Crew__c || "",
          id: conId,
          name: timecardMap[conId].name,
          role: timecardMap[conId].role,
          status: timecardMap[conId].status || "NA",
          calendar: "allday",
          timeCardAvailable: true,
          timeCardDetail: timecardMap[conId],
          isTimecard: true,
          timeOffDates: timecardMap[conId].timeOffDates,
          Crew_Accountable: contactIdDetails[conId]?.Crew_Accountable || "",
        };
        resources.push(tp);
      }
    });
  }
  let returnList = uniqBy(
    resources.filter(
      (res) => !resourceDeleted.includes(res.id) || !isDailyReportView
    ),
    "id"
  );
  return returnList.map((r) => {
    if (recIds.includes(r.Contact__c)) {
      return { ...r, isProjectResource: true };
    }
    return { ...r, isProjectResource: false };
  });
};

const getDuration = (start, end) => {
  if (start && end) {
    return DateHelper.diff(
      DateHelper.parse(start, "YYYY-MM-DD"),
      DateHelper.parse(end, "YYYY-MM-DD"),
      "hours"
    );
  }
  return null;
};

let parentTasks = [];
const findParentTask = (data, parentIds) => {
  if (parentIds.length > 0) {
    const uniqParents = uniq(parentIds);
    const np = filter(data, (d) => uniqParents.includes(d.Id));
    parentTasks = [...parentTasks, ...np];
    if (np.length > 0) {
      const pids = [];
      np.forEach((n) => (n.Parent__c ? pids.push(n.Parent__c) : null));
      findParentTask(data, pids);
    }
  }
  return [...parentTasks];
};

export const filterByTimeline = (data, startDate, endDate) => {
  const events = [];
  const parentsToFind = [];
  data.forEach((d) => {
    if (d.Type__c === "Task" && d.Start_Date__c && d.End_Date__c) {
      // pass 1 find tasks
      const taskObj = {
        id: d.Id,
        name: d.WBS_Name__c,
        wbs: d.WBS_Number__c,
        startDate: moment(d.Start_Date__c).format("YYYY-MM-DD"),
        endDate: moment(d.End_Date__c).format("YYYY-MM-DD"),
      };
      if (d.Parent__c) {
        taskObj.parentId = d.Parent__c;
      } else {
        taskObj.parentId = null;
      }
      if (
        moment(taskObj.startDate).isSameOrAfter(
          startDate.format("YYYY-MM-DD")
        ) &&
        moment(taskObj.endDate).isSameOrBefore(endDate.format("YYYY-MM-DD"))
      ) {
        events.push(taskObj);
        if (taskObj.parentId) {
          // find all parents in hierarchy
          parentsToFind.push(taskObj.parentId);
        }
      }
    }
  });

  // find parents
  parentTasks = [];
  const parents = findParentTask(data, parentsToFind);
  parents.forEach((d) => {
    const taskObj = {
      id: d.Id,
      name: d.WBS_Name__c,
      wbs: d.WBS_Number__c,
      startDate: moment(d.Start_Date__c).format("YYYY-MM-DD"),
      endDate: moment(d.End_Date__c).format("YYYY-MM-DD"),
    };
    if (d.Parent__c) {
      taskObj.parentId = d.Parent__c;
    } else {
      taskObj.parentId = null;
    }
    events.push(taskObj);
  });
  return arrayToTree(events);
};

export const processEvents = (data) => {
  const events = [];
  data.forEach((d) => {
    if (d.Type__c === "Task") {
      const taskObj = {
        id: d.Id,
        name: d.WBS_Name__c,
        wbs: d.WBS_Number__c,
        startDate: d.Start_Date__c,
        endDate: d.End_Date__c,
      };
      if (d.Parent__c) {
        taskObj.parentId = d.Parent__c;
      } else {
        taskObj.parentId = null;
      }
      events.push(taskObj);
    }
  });
  return arrayToTree(events);
};

export const checkIsWeekendAvailable = (data) => {
  return data.some((d) =>
    ["Sat", "Sun"].includes(moment(d.startDate).format("ddd"))
  );
};

export const processAssignments = (data) => {
  const assignments = [];
  data.forEach((d) => {
    const assignObj = {
      id: d.Id,
      key: `uikey-${StringHelper.generateUUID()}`, // this is a unique key for ui
      parentId: d.Parent_WBS__c,
      eventId: d.WBS__c,
      resourceId: d.Contact__c,
      startDate: d.Task_Date__c,
      duration: d.Scheduled_Hours__c,
      status: d.Status__c,
      isDeleted: d.isDeleted__c,
      rDeleted: d.Resource_Deleted__c,
      durationUnit: "h",
      units: 100,
      tags: d.Tags__c ? d.Tags__c.split(";") : [],
      notes: d.Notes__c || "",
    };
    assignments.push(assignObj);
  });
  return assignments;
};

export const generateDates = (date, timeline, showWeekend = false) => {
  const excludeDays = showWeekend ? [] : ["Sat", "Sun"];
  const datesArr = [];
  if (timeline === 1) {
    datesArr.push(moment(date));
  } else {
    for (let i = 1; i <= timeline; i++) {
      const day = moment(date).weekday(i).format("ddd");
      if (!excludeDays.includes(day)) {
        datesArr.push(moment(date).weekday(i));
      }
    }
  }
  return datesArr;
};

export const findDropParentNode = (target) => {
  const td = target.closest("td");
  if (td) {
    return target.closest("td").id;
  }
  return null;
};

export const findDropAssignment = (target) => {
  const assignDiv = target.closest("div.lh-task-dropable-box");
  if (assignDiv) {
    return assignDiv.id;
  }
  return null;
};

const isWeekend = date => {
  const day = moment(date).format("ddd");
  return ["Sat", "Sun"].includes(day);
}

export const getDateRange = (firstDate, lastDate) => {
  if (moment(firstDate, 'MM-DD-YYYY').isSame(moment(lastDate, 'MM-DD-YYYY'))){
    return [moment(lastDate).format("MM-DD-YYYY")];
  }
  let date = firstDate;
  const dates = [moment(date).format("MM-DD-YYYY")];
  do {
    date = moment(date).add(1, 'day');
    if(!isWeekend(date.format("MM-DD-YYYY"))) {
      dates.push(date.format('MM-DD-YYYY'));
    }
  } while (moment(date).isBefore(lastDate));
  return dates;
};
